.containerStyle {
  width: 60%;
  height: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  text-align: center;
  overflow: hidden;
  z-index: 1;
  background-image: url('../../assets/Back.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  color: white;
  border-radius: 10px;
  box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.5);
}
.modal-backdrop-full {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1040; 
}

.buttonContainer {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.btn-secondary {
  font-size: 12px;
  padding: 10px 20px;
  background-color: #6a5b86d0;
  border: none;
  border-radius: 5px;
  color: rgb(0, 0, 0);
  transition: background-color 0.3s ease;
  margin-left: 10px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
}

.shorter-input {
  width: 100%;
  max-width: 250px;
  height: 30px;
  padding: 5px 10px;
  box-shadow: 5px 5px 5px rgba(58, 57, 57, 0.5);
  align-items: center;
}

.btn-secondary.btn-ok {
  color: black;
  font-weight: bold;
}

.buttonContainer button {
  margin-top: 10px;
  font-size: 16px;
  padding: 8px 20px;
  background-color: #6a5b86a9;
  border: none;
  border-radius: 5px;
  color: white;
  transition: background-color 0.3s ease;
  box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.5);
  margin-left: 10px;
}

.place1 {
  padding-top: 40px;
}

.place2 {
  padding-top: 70px;
}

.fullPageBackground {
  background-color: #e8e1f5a8;
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.dropdown-toggle {
  font-size: 18px;
  padding: 10px 20px;
  background-color: #6a5b86a9;
  border-color: transparent;
  transition: background-color 0.3s ease;
  color: white;
  border-radius: 5px;
}

.dropdown-toggle:hover {
  background-color: #5b1bc2;
  border-color: transparent;
}

.dropdown-toggle:active {
  background-color: #5b1bc2;
}

.dropdown-menu {
  background-color: #e0d8ec;
  max-height: 200px;
  overflow-y: auto;
}

.dropdown-item {
  color: rgb(0, 0, 0);
  font-size: medium;
  font-weight: normal;
}

.dropdown-item:hover {
  background-color: #985cf7;
}

.dropdown-item:active {
  background-color: #5b1bc2;
}

.modal-content {
  background-color: #a083d6f5;
  color: black;
}

.btn2 {
  border-radius: 20px;
}

@media screen and (max-width: 768px) {
  .containerStyle {
    width: 80%;
    height: auto;
    padding: 10px;
    font-size: 14px;
  }

  .buttonContainer {
    position: relative;
    text-align: center;
    margin-top: 20px;
  }

  .buttonContainer button {
    font-size: 14px;
    padding: 6px 16px;
    margin-left: 0;
  }
}
