.scrollable-list {
  max-height: 90vh;
  overflow-y: auto;
}

.formulaires-container {
  width: 100%;
}

.versions-wrapper {
  display: flex;
  justify-content: space-between;
}

.versions-column2 {
      width: 20%;
      height: 100vh;
      background-color: rgba(208, 195, 248,0.2);
      border: 1px solid rgb(102, 16, 242);
      right: 0;
      top: 52px;
      position: absolute;
}

.versions-column1 {
  margin-top: 3%;
  margin-left: 3%;
}

.navbar {
  max-width: 100%;
  background-color: #310f68;
  max-height: 60px;
}

.list-group-item {
  font-size: x-small;
  font-weight: bold;
  color: rgb(0, 0, 0);
  background-color: rgb(208, 195, 248);
  font-family: 'Montserrat', sans-serif;
}

.offcanvas-top {
  max-height: max-content;
  background-color: #ffffff;
}

.color {
  color: rgb(0, 0, 0);
}

.btn {
  background-color: #cfbcee98;
  color: white;
  border-color: transparent;
}

.btn:hover,
.btn:active,
.btn:focus {
  background-color: #cfbcee98;
  color: white;
}

.clickable {
  cursor: pointer;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1040;
  pointer-events: none;
}

.offcanvas-backdrop.show {
  display: block;
}

.center-content {
  justify-content: center;
}

/* Media queries pour le responsive */

@media screen and (max-width: 768px) {
  .versions-wrapper {
    flex-direction: column;
  }

  .versions-column2 {
    flex-basis: 100%;
  }

  .navbar {
    max-height: 80px;
  }
}
